import React, { useEffect, useRef, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import LoaderComp from "component/loader/LoaderComp";
import { awsURL } from "utils/Constants";
import { BiEditAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const CustomTableSelected = ({
  columns,
  data,
  isLoading,
  tableContainerClass,
  customTableClass,
  renderAction,
  selectedItems,
  setSelectedItems,
  handleSelectAll,
  allSelect,
  handleToggleIcon,
  selectedArrow,
  handleGenerateLetter,
  handleUpdateLetter,
  handleSendEmail,
}) => {
  const { t } = useTranslation();
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef([]);
  const [index, setIndex] = useState(null);
  const handleToggleDropdown = (rowId, jobIndex) => {
    setOpenDropdownId((prev) => (prev === rowId ? null : rowId));
    setIndex((prev) => (prev === jobIndex ? null : jobIndex));
  };

  useEffect(() => {
    const handler = (event) => {
      if (
        openDropdownId !== null &&
        dropdownRef.current[index] &&
        !dropdownRef.current[index]?.contains(event.target)
      ) {
        setOpenDropdownId(null);
        setIndex(null);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [openDropdownId]);

  return (
    <div
      className={`w-full border border-[#E2E8F0] rounded ${tableContainerClass}`}
    >
      <table className={`w-full rounded ${customTableClass}`}>
        <thead className="w-full bg-[#F2F6FF] text-[#686868] sticky top-0 z-20">
          <tr className="h-[70px] w-[151px]">
            {columns?.map((col) => (
              <th className="px-[15px]" key={col} align={col?.align}>
                <div className="flex gap-3">
                  {col?.inputcheck && (
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        disabled={!data?.length}
                        checked={allSelect}
                        className="h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border relative
                        accent-[#031B59!important] checked:bg-[#031B59!important]
                          checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                          checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                          checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                          checked:after:rotate-[45deg]
                          checked:after:border-[0.15rem] checked:after:border-l-0
                          checked:after:border-t-0 checked:after:border-solid checked:after:border-white
                          checked:after:bg-transparent checked:after:content-[''] cursor-pointer"
                        onChange={(e) =>
                          handleSelectAll(e.target.checked, data)
                        }
                      />
                    </div>
                  )}
                  <div className="flex  w-full">
                    {col?.title}
                    {col?.icon && <col.icon />}
                  </div>
                </div>
              </th>
            ))}
            {renderAction && (
              <th className="min-w-[5rem] w-fit text-left px-[15px] text-[#686868] capitalize">
                {t("action")}{" "}
              </th>
            )}
          </tr>
        </thead>
        <tbody className={`w-full h-full text-gray-700 `}>
          {isLoading ? (
            <tr>
              <td colSpan={columns?.length} align="center">
                <LoaderComp />
              </td>
            </tr>
          ) : data?.length === 0 ? (
            <tr className="w-full h-full">
              <td
                className="w-full h-full"
                colSpan={columns?.length + 1}
                align="center"
              >
                <div className="w-full h-full flex flex-col gap-3 items-start sm:items-center justify-center">
                  <img
                    className="w-auto h-[10rem] md:h-[15rem] lg:h-[20rem]"
                    src={`${awsURL}/images/portfolio_filter_NoDataFound.webp`}
                    alt="No Data Found"
                  />
                  <p className="text-[#031B59] text-2xl max-w-[10em] lg:max-w-none">
                    The Data you are looking for could not be found.
                  </p>
                </div>
              </td>
              {/* </div> */}
            </tr>
          ) : (
            data?.map((job, jobIndex) => (
              <>
                <tr
                  className={`h-[4.5rem] ${jobIndex % 2 === 1 ? "bg-[#F3F6FD]" : "bg-[#fff]"
                    }`}
                  key={job.id}
                >
                  {columns?.map((col) => (
                    <td
                      key={col?.field}
                      className={
                        col?.className
                          ? col?.className
                          : "min-w-[151px] p-[24px] h-[50px] text-[#686868] "
                      }
                      align={col?.align}
                    >
                      <div className="flex gap-3 items-center">
                        {col?.inputcheck && (
                          <input
                            type="checkbox"
                            className="h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border relative
                            accent-[#031B59!important] checked:bg-[#031B59!important]
                            checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                            checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                            checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                            checked:after:rotate-[45deg]
                            checked:after:border-[0.15rem] checked:after:border-l-0
                            checked:after:border-t-0 checked:after:border-solid checked:after:border-white
                            checked:after:bg-transparent checked:after:content-[''] cursor-pointer"
                            checked={
                              selectedItems?.hasOwnProperty(job.id)
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              setSelectedItems(job?.id, e.target.checked)
                            }
                          />
                        )}
                        {col?.sideIcon && (
                          <div
                            onClick={() => handleToggleIcon(job)}
                            className="cursor-pointer"
                          >
                            {selectedArrow === job?.id ? (
                              <FaChevronDown />
                            ) : (
                              <div>
                                <FaChevronRight />
                              </div>
                            )}
                          </div>
                        )}
                        <div>
                          {col?.render
                            ? col.render(job[col?.field], job, jobIndex)
                            : job[col?.field] === (null || undefined || "")
                              ? "- - - - - "
                              : job[col?.field]}
                          {/* {toggle && col?.sideIcon && <div>Hello</div>} */}
                        </div>
                      </div>
                    </td>
                  ))}
                 {renderAction && (
                  <td className="cursor-pointer">
                    <div
                      className="w-full flex items-center justify-start px-[15px]"
                      ref={(el) => (dropdownRef.current[jobIndex] = el)} // Assign ref
                    >
                      {renderAction(
                        job,
                        jobIndex,
                        openDropdownId,
                        dropdownRef,
                        handleToggleDropdown
                      )}
                    </div>
                  </td>
                )}

                </tr>
                {selectedArrow === job?.id && (
                  <tr>
                    <td
                      colSpan={columns.length + 1}
                      className={`py-2 px-5 ${jobIndex % 2 === 1 ? "bg-[#F3F6FD]" : "bg-[#fff]"
                        }`}
                    >
                      <div className="flex justify-between">
                        <div className="flex">
                          {job?.offerLetterExist === false ? (<button
                            className="border text-[#1B4FB6] h-9 w-[9rem] rounded-xl ml-2"
                            onClick={() => handleGenerateLetter(job)}
                          >
                            {t("generateLetter")}
                          </button>) : (
                            <button className="border text-[#1B4FB6] h-9 w-[8rem] rounded-xl flex items-center justify-around ml-2"
                              onClick={() => handleUpdateLetter(job)}>
                              {t("offerLetter")}
                              <span>
                                <BiEditAlt />
                              </span>
                            </button>)}
                        </div>
                        <div className="flex gap-3">
                          <button className="border text-[#031b59] font-medium h-9 w-[6rem] bg-[#DEE4EB] rounded-xl">
                            {t("update")}
                          </button>
                          <button className="border h-9 w-[12rem] rounded-xl">
                            <sapn className="text-[#1A8718]">
                              {t("accepted")}{" "}
                            </sapn>
                            (19 Nov. 2023)
                          </button>
                          <button className="border text-[#1B4FB6] h-9 w-[8rem] rounded-xl"
                            onClick={() => handleSendEmail(job)}>
                            {t("senEmail")}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))
          )}
          {/* Add more rows here */}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTableSelected;
