import { useOnClickOutside } from "component/common/useOneClickOutsid";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MdRefresh } from "react-icons/md";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";
import useDebounce from "hooks/useDebounce";
import { setCheckData } from "reduxToolkit/slices/JobSlice";
import Chips from "./Chips";
import { useLocation } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { filterOptionsArr, referenceOptions, statusOptions } from "utils/Constants";
import DatePicker from "./DatePicker";
import { FaRegCalendarAlt } from "react-icons/fa";

const FilterColumn = ({
  newClass,
  filterOptions,
  filters,
  disabledFilterState,
  showFilter,
  onClose,
  setFilterState,
  onChange,
  onSave,
  onRefresh,
  activeItemId,
  setActiveItemId,
  filterState,
  handleLocationSelect,
  tabValue

}) => {
  const { t } = useTranslation();
  const { searchResult } = useSelector((state) => state.jobs);
  const filterRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.includes("/applicants");
  const [guestName, setGuestName] = useState("");
  const [appliedDate, setAppliedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [handleStatusOpen, setHandleStatusOpen] = useState(false);
  const [handleReferenceOpen, setHandleReferenceOpen] = useState(false);
  const [applySearch, setApplySearch] = useState("");
  const [handleByOpen, setHandleByOpen] = useState(false);
  const [handleLocationOpen, setHandleLocationOpen] = useState(false);
  const { getCheckData, getAllOpeningsData } = useSelector(
    (state) => state.jobs
  );
  const [checkItem, setCheckItem] = useState(
    getCheckData?.length > 0 ? getCheckData : []
  );
  const [checkItems, setCheckItems] = useState(
    getCheckData?.length > 0 ? getCheckData : []
  )
  let searchQuery = useDebounce(guestName, 300);

  useOnClickOutside(filterRef, () => {
    onClose();
    setActiveItemId(false);
  });

  const openingData = getAllOpeningsData?.map((item) => item?.title);
  const handleOpen = (id, date) => {
    const stateMap = {
      0: {
        setAppliedDate: date,
        handleByOpen: false,
        handleLocationOpen: false,
      },
      1: {
        activeItemId: !activeItemId,
        handleByOpen: false,
        handleLocationOpen: false,
      },
      2: {
        handleByOpen: !handleByOpen,
        activeItemId: false,
        handleLocationOpen: false,
      },
      3: {
        handleByOpen: false,
        activeItemId: false,
        handleLocationOpen: !handleLocationOpen,
      },
      4: {
        handleStatusOpen: !handleStatusOpen,
        activeItemId: false,
        handleByOpen: false,
        handleLocationOpen: false,
        handleReferenceOpen: false
      },
      5: {
        handleReferenceOpen: !handleReferenceOpen,
        activeItemId: false,
        handleByOpen: false,
        handleLocationOpen: false,
        handleStatusOpen: false
      }
    };

    const newState = stateMap[id];

    if (newState) {
      setActiveItemId(newState.activeItemId);
      setHandleByOpen(newState.handleByOpen);
      setHandleLocationOpen(newState.handleLocationOpen);
      setHandleStatusOpen(newState.handleStatusOpen);
      setHandleReferenceOpen(newState.handleReferenceOpen);
    }
  };

  const handleSelect = (data) => {
    
    setCheckItem((prev) => {
      const checkData = prev?.some((item) => item?.name === data?.name);
      return checkData
        ? prev?.filter((item) => item.name !== data.name)
        : [...prev, data];
    });
  };
  const handleSelects = (data) => {
    setCheckItems((prev) => {
      const checkData = prev?.some((item) => item?.name === data?.name);
      return checkData
        ? prev?.filter((item) => item.name !== data.name)
        : [...prev, data];
    })
  }
  const handleReset = () => {
    onRefresh();
    setCheckItem([]);
  };

  useEffect(() => {
    dispatch(setCheckData(checkItem));
    setFilterState((prevState) => ({
      ...prevState,
      status: checkItem,
      references: checkItems
    }));
  }, [checkItem, checkItems]);

  useEffect(() => {
    if (guestName !== "") {
      dispatch(handleSearchUserForChat(searchQuery));
    }
  }, [searchQuery, dispatch]);

  const appliedType = openingData?.filter((item) =>
    item?.toLowerCase().includes(applySearch.toLowerCase())
  );
  const newType = applySearch ? appliedType : openingData;

  return (
    showFilter && (
      <div
        ref={filterRef}
        className={`flex flex-col gap-2 max-h-[43rem] w-[16.625rem] bg-white ${newClass}
            shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-30 border-[1px] rounded-md border-[#E2E8F0] animate-popover`}
      >
        <div className="flex justify-between items-center py-2 pt-4 px-3">
          <h1 className="text-[#002169] font-bold text-lg">{t("filters")}</h1>
          <div className="" onClick={() => onClose()}>
            <RxCross1 />
          </div>
        </div>
        <hr />
        {getCheckData?.length > 0 && (
          <div className="px-4">
            <div className="w-full h-full max-h-[6rem] overflow-auto space-y-2 custom_scroll ">
              <div className="flex flex-wrap gap-2">
                {getCheckData?.map((item) => {
                  return <Chips item={item} handleRemove={handleSelect} />;
                })}
              </div>
            </div>
          </div>
        )}
        {/* upper section of jobOpening column filter */}
        <div className="flex h-fit flex-col gap-2 p-2">
          <div
            className="border-2 rounded-md p-2 flex justify-between items-center cursor-pointer"
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <h1>{tabValue === 3 ? "Filter by Onboarding date" : "Filter by applied date"}</h1>
            <FaRegCalendarAlt className="w-5 h-5" />
          </div>

          {showCalendar && (
            <div className="p-2 border-2 rounded-md">
              <DatePicker
                selected={appliedDate}
                handleChange={(e) => {
                  setFilterState((prevState) => ({
                    ...prevState,
                    [tabValue !== 3 ? "dates" : "onboardingDates"]: e.target.value
                  }));
                }}
                
                // handleChange={(e) => { tabValue !== 3 ? setCheckeDate(e.target.value) : setCheckeOnboardingDate(e.target.value) }}
                placeholderText="Select a date"
                className="w-full border rounded-[0.5rem] p-2"
              />
            </div>
          )}
          <div>
            {path && (
              <div
                className="border-2 rounded-md p-2 flex justify-between items-center cursor-pointer"
                onClick={() => handleOpen("1")}
              >
                <h1>{t("appliedFor")}</h1>
                <div>
                  <BsChevronDown />
                </div>
              </div>
            )}
          </div>

          {activeItemId && (
            <>
              <div className="h-fit flex flex-col gap-2 p-2 border-2 rounded-md ">
                <div className="w-full border rounded-[0.5rem] flex flex-col gap-2">
                  <input
                    className=" w-full
            h-[2.5rem] p-2  rounded-[0.5rem]"
                    placeholder="Search..."
                    value={applySearch}
                    onChange={(e) => setApplySearch(e.target.value)}
                  />
                </div>
                <div className=" h-[7rem] overflow-y-auto custom_scroll flex flex-col gap-[0.7rem]">
                  {newType?.length > 0 ? (
                    newType?.map((data) => {
                      return (
                        <div className="flex space-x-3 items-center w-[206px]">
                          <div className="h-[20px] w-[20px]">
                            <input
                              className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                    checked:bg-[#031B59] h-[20px] w-[20px]"
                              type="checkbox"
                              checked={
                                checkItem?.find((item) => item.name === data)
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleSelect({ name: data, id: null })
                              }
                            />
                          </div>
                          <div className="whitespace-normal break-all">
                            {data}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div> {t("noDataFound")}</div>
                  )}
                </div>
              </div>
            </>
          )}
          <div
            className="border-2 rounded-md p-2 flex justify-between items-center cursor-pointer"
            onClick={() => handleOpen("2")}
          >
            <h1>{t("handledBy")}</h1>
            <div>
              <BsChevronDown />
            </div>
          </div>
          {handleByOpen && (
            <div className="p-2 border-2 rounded-md">
              <div className="w-full border rounded-[0.5rem]">
                <input
                  value={guestName}
                  className=" w-full
                  h-[2.5rem] p-2 rounded-[0.5rem]"
                  placeholder="Search..."
                  type="text"
                  onChange={(e) => setGuestName(e.target.value)}
                />
              </div>
              {guestName !== "" && (
                <div className="h-[7rem] overflow-y-auto custom_scroll mt-1">
                  {searchResult.length > 0 ? (
                    searchResult.map((employee) => (
                      <div
                        key={employee.id}
                        className="flex gap-2 items-center
                 text-black h-[2rem] px-2 cursor-pointer hover:bg-gray-100"
                      >
                        <input
                          className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                    checked:bg-[#031B59] h-[20px] w-[20px]"
                          type="checkbox"
                          checked={
                            checkItem?.find(
                              (item) => item.name === employee.name
                            )
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleSelect({
                              name: employee?.name,
                              id: employee?.id,
                            })
                          }
                        />

                        <p>{employee.name}</p>
                      </div>
                    ))
                  ) : (
                    <div> {t("noDataFound")}</div>
                  )}
                </div>
              )}
            </div>
          )}
          <div
            className="border-2 rounded-md p-2 flex justify-between items-center cursor-pointer"
            onClick={() => handleOpen("3")}
          >
            <h1>{t("location")}</h1>
            <div>
              <BsChevronDown />
            </div>
          </div>
          {handleLocationOpen && (
            <div className="p-2 border-2 rounded-md">
              {filterOptionsArr?.map((item, index) => (
                <div
                  key={index}
                  className="h-[2rem] w-auto space-x-3 flex items-center"
                >
                  <input
                    className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                                            checked:bg-[#031B59] h-[20px] w-[20px]"
                    id={item}
                    type="checkbox"
                    // disabled={disabledFilterState.includes(item)}
                    checked={filterState?.locations?.includes(item)}
                    onChange={() => handleLocationSelect(item)}
                  />
                  <span
                    className={`${filterState?.locations[item]
                      ? "text-[#031B59]"
                      : "text-[#191919]"
                      }`}
                  >
                    {item}
                  </span>
                </div>
              ))}
            </div>
          )}
          <div
            className="border-2 rounded-md p-2 flex justify-between items-center cursor-pointer"
            onClick={() => handleOpen("5")}
          >
            <h1>{t("Reference")}</h1>
            <div>
              <BsChevronDown />
            </div>
          </div>
          {handleReferenceOpen && (
            <div className="p-2 border-2 rounded-md">
              {referenceOptions?.map((ref, index) => (
                <div key={index} className="h-[2rem] space-x-3 flex items-center">
                  <input
                    type="checkbox"
                    className="rounded-[6px] focus:ring-transparent accent-[#031B59] checked:bg-[#031B59] h-[20px] w-[20px]"
                    onChange={() => handleSelects({ name: ref, id: index })}
                    checked={checkItems?.find((item) => item.name === ref) ? true : false}
                  />
                  <span>{ref}</span>
                </div>
              ))}
            </div>
          )}
          <div
            className="border-2 rounded-md p-2 flex justify-between items-center cursor-pointer"
            onClick={() => handleOpen("4")}
          >
            <h1>{t("status")}</h1>
            <div>
              <BsChevronDown />
            </div>
          </div>
          {handleStatusOpen && (
            <div className="p-2 border-2 rounded-md">
              {statusOptions?.map((status, index) => (
                <div key={index} className="h-[2rem] space-x-3 flex items-center">
                  <input
                    type="checkbox"
                    className="rounded-[6px] focus:ring-transparent accent-[#031B59] checked:bg-[#031B59] h-[20px] w-[20px]"
                    onChange={() => handleSelect({ name: status, id: index })}
                    checked={checkItem?.find((item) => item.name === status) ? true : false}
                  />
                  <span
                    className={`${status.includes("Interviewing") ? "text-[#ED6E0F]" : "text-green-500"
                      }`}
                  >
                    {status}
                  </span>
                </div>
              ))}
            </div>

          )}
        </div>
        <div className="px-3">
          <h1 className="text-[#002169] font-bold text-lg">
            {t("columnFilter")}
          </h1>
        </div>
        {/* column filter */}
        <div className="max-h-[17rem] overflow-y-scroll custom_scroll w-full flex flex-col gap-[0.8rem] p-3">
          {filterOptions?.map((item, index) => (
            <div
              key={index}
              className="max-h-[2.25rem] w-[13.313rem] space-x-3 flex items-center"
            >
              <input
                className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                    checked:bg-[#031B59] h-[20px] w-[20px]"
                id={item.key}
                type="checkbox"
                disabled={disabledFilterState.includes(item.key)}
                checked={filters[item.key] ?? false}
                onChange={onChange}
              />
              <span
                className={`${filters[item.key] ? "text-[#031B59]" : "text-[#191919]"
                  }`}
              >
                {item.value}
              </span>
            </div>
          ))}
        </div>
        <div className="w-full h-full flex items-center justify-between space-x-3 bg-transparent px-3 py-2">
          <button
            className="h-[3.063rem] w-full basis-1/3 flex items-center justify-center border-[1.5px]
                rounded-md enabled:border-[#031B59] enabled:text-[#031B59] disabled:border-[#A1A1A1]
                disabled:text-[#A1A1A1] disabled:opacity-80"
            type="submit"
            onClick={handleReset}
          >
            <MdRefresh className="h-5 w-5 flex items-center justify-center" />
          </button>
          <button
            className="h-[3.063rem] basis-2/3 p-2 border-[1.5px]  enabled:border-[#031B59] enabled:text-[#031B59]
                disabled:border-[#A1A1A1] disabled:text-[#A1A1A1] disabled:opacity-80 font-medium rounded-md"
            type="submit"
            onClick={onSave}
          >
            {t("save")}
          </button>
        </div>
      </div>
    )
  );
};

FilterColumn.propTypes = {
  filterOptions: PropTypes.array,
  filterState: PropTypes.object,
  disabledFilterState: PropTypes.array,
  showFilter: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default FilterColumn;

