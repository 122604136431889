import { useOnClickOutside } from "component/common/useOneClickOutsid";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdRefresh } from "react-icons/md";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";
import useDebounce from "hooks/useDebounce";
import { setCheckData } from "reduxToolkit/slices/JobSlice";
import { CurrentOpeningChip } from "./Chips";
import { RxCross1 } from "react-icons/rx";

const FILTER_OPTIONS = {
  appliedFor: "Applied For",
  jobType: "Job Type",
  location: "Location",
  status: "Status",
};

const JOB_TYPES = ["Remote", "On-site"];
const LOCATIONS = ["Jaipur", "Pune", "Indore", "Bengaluru"];
const STATUSES = ["Open", "Close"];

const CurrentOpeningsFilterColumn = ({
  newClass,
  showFilter,
  onClose,
  onRefresh,
  setCheckJobType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterRef = useRef();
  const { getCheckData, getAllOpeningsData } = useSelector(
    (state) => state.jobs
  );

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedQuery = useDebounce(searchQuery, 300);
  const [selectedFilters, setSelectedFilters] = useState(
    getCheckData || {
      appliedFor: "",
      jobType: [],
      location: [],
      status: [],
    }
  );
  const [activeFilter, setActiveFilter] = useState(null);

  useOnClickOutside(filterRef, () => {
    onClose();
    setActiveFilter(null);
  });

  useEffect(() => {
    dispatch(setCheckData(selectedFilters));
  }, [selectedFilters]);

  useEffect(() => {
    if (debouncedQuery) {
      dispatch(handleSearchUserForChat(debouncedQuery));
    }
  }, [debouncedQuery, dispatch]);

  const handleSelect = (key, value) => {
    setSelectedFilters((prev) => {
      if (key === "appliedFor") {
        return { ...prev, appliedFor: prev.appliedFor === value ? "" : value };
      }

      const currentValues = Array.isArray(prev[key]) ? prev[key] : [];
      return {
        ...prev,
        [key]: currentValues.includes(value)
          ? currentValues.filter((item) => item !== value)
          : [...currentValues, value],
      };
    });
  };

  const handleRemove = (filterKey, value) => {
    setSelectedFilters((prev) => {
      if (filterKey === "appliedFor") {
        return { ...prev, appliedFor: "" };
      }

      const updatedValues = prev[filterKey].filter((item) => item !== value);
      return { ...prev, [filterKey]: updatedValues };
    });
  };

  const handleSave = () => {
    const updatedFilters = {
      ...selectedFilters,
      status:
        selectedFilters?.status?.includes("Open") &&
          selectedFilters?.status?.includes("Close")
          ? ["All"]
          : selectedFilters?.status,
    };
    setCheckJobType({ ...updatedFilters });
    onClose();
  };


  const handleReset = () => {
    onRefresh();
    setSelectedFilters({
      appliedFor: "",
      jobType: [],
      location: [],
      status: [],
    });
  };

  return (
    showFilter && (
      <div
        ref={filterRef}
        className={`flex flex-col gap-3 w-64 bg-white ${newClass} shadow-lg border rounded-md p-3 z-30 animate-popover`}
      >
        <div className="flex justify-between items-center pb-2">
          <h1 className="text-blue-900 font-bold text-lg">{t("filters")}</h1>
          <button onClick={onClose} className="text-gray-600 hover:text-black">
            <RxCross1 />
          </button>
        </div>
        <hr />
        {Object.keys(selectedFilters).length > 0 && (
          <div className="px-2 max-h-24 h-fit overflow-auto flex flex-wrap gap-2">
            {Object.entries(selectedFilters)
              .flatMap(([key, values]) =>
                (Array.isArray(values) ? values : values ? [values] : []).map(
                  (value) => ({
                    key,
                    value,
                  })
                )
              )
              .map(({ key, value }) => (
                <CurrentOpeningChip
                  key={`${key}-${value}`}
                  item={`${value}`}
                  handleRemove={() => handleRemove(key, value)}
                />
              ))}
          </div>
        )}

        <div className="flex flex-col gap-2">
          {Object.entries(FILTER_OPTIONS).map(([key, label]) => (
            <React.Fragment key={key}>
              <button
                onClick={() =>
                  setActiveFilter(activeFilter === key ? null : key)
                }
                className="border rounded-md p-2 flex justify-between items-center"
              >
                <span>{t(label)}</span>
                <BsChevronDown />
              </button>
              {activeFilter === key && (
                <div className="p-2 border rounded-md flex flex-col gap-2">
                  {key === "appliedFor" && (
                    <input
                      className="border rounded p-2"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  )}
                  <div className="overflow-y-auto">
                    {key === "appliedFor" &&
                      getAllOpeningsData?.map(({ title }) => (
                        <label key={title} className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            className="h-5 w-5"
                            checked={selectedFilters.appliedFor === title}
                            onChange={() => handleSelect("appliedFor", title)}
                          />
                          {title}
                        </label>
                      ))}
                    {key === "jobType" &&
                      JOB_TYPES.map((type) => (
                        <label key={type} className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            className="h-5 w-5"
                            checked={selectedFilters?.jobType?.includes(type)}
                            onChange={() => handleSelect("jobType", type)}
                          />
                          {type}
                        </label>
                      ))}
                    {key === "location" &&
                      LOCATIONS.map((type) => (
                        <label key={type} className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            className="h-5 w-5"
                            checked={selectedFilters?.location?.includes(type)}
                            onChange={() => handleSelect("location", type)}
                          />
                          {type}
                        </label>
                      ))}
                    {key === "status" &&
                      STATUSES.map((type) => (
                        <label key={type} className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            className="h-5 w-5"
                            checked={selectedFilters?.status?.includes(type)}
                            onChange={() => handleSelect("status", type)}
                          />
                          {type}
                        </label>
                      ))}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex justify-between px-3 py-2">
          <button
            onClick={handleReset}
            className="h-12 w-1/3 border rounded-md flex items-center justify-center text-blue-900"
          >
            <MdRefresh className="h-5 w-5" />
          </button>
          <button
            onClick={handleSave}
            className="h-12 w-2/3 border rounded-md text-blue-900 font-medium"
          >
            {t("save")}
          </button>
        </div>
      </div>
    )
  );
};

export default CurrentOpeningsFilterColumn;
